

















import Vue from 'vue';
import Component from 'vue-class-component'
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  components: { SygniRoundedButton },
})
export default class PostBox extends Vue {
  @Prop({default: false}) featured: boolean;
  @Prop({}) details: any;

  get detailsTag() {
    return this.details.tags.join(', ');
  }

  get detailsTagsExcerpt() {
    if(this.detailsTag.length > 40) return this.detailsTag.slice(0, 40) + ' ...'

    return this.detailsTag;
  }
}
