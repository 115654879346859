

































import Component, { mixins } from 'vue-class-component'
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniMultiSelect from "@/components/inputs/SygniMultiSelect.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import SygniRangeInput from "@/components/inputs/SygniRangeInput.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniButtonsGroup from "@/components/layout/SygniButtonsGroup.vue";
// import {SelectOption} from "@/store/types";
import {Filter, FilterFunctions, FilterMixin, FilterOperators} from "@/shared/mixins/FilterMixin";
// import {AddProductSelects, InvestorSelects} from "@/modules/shared/dictionary-store/types";
import {mapGetters } from "vuex";
import SygniCheckableButton from "@/components/buttons/SygniCheckableButton.vue";
import { Dictionaries } from '@/modules/accounting/store/types';
import { Watch } from 'vue-property-decorator';
import Utils from '@/modules/shared/utils/utils';
import { SelectOption } from '@/store/types';

@Component({
  components: {
    SygniCheckableButton,
    SygniRangeInput,
    SygniButtonsGroup,
    SygniDatePicker, SygniContainerTitle, SygniRoundedButton, SygniSquareButton, SygniSelect, SygniInput, SygniMultiSelect
  },
  computed: {
    ...mapGetters('profile', {
      dictionaries: 'getDictionaries',
    })
  }
})
export default class InsightFilters extends mixins (FilterMixin) {
  dictionaries!: Dictionaries;
  accountingPeriod: string = '';
  selectedTags: Array<SelectOption<string>> = [];
  selectedIndustries: Array<SelectOption<string>> = [];
  itemsUrl: string = 'insight';

  filters: Record<string, Filter> = {
    title: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    author: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    publishedAt: { value: null, getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
    language: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    tags: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.contain, type: 'string' },
    industry: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.contain, type: 'string' },
  };

  updateTags(tags: Array<string>) {
    this.filters.tags.value = tags;
  }

  updateIndustries(focusAreas: Array<string>) {
    this.filters.industry.value = focusAreas;
  }

  setAccountingPeriod(date: any): void {
    let dateTime = new Date(date);
    dateTime = new Date(dateTime.getFullYear(), dateTime.getMonth() + 1, 0);
    const dateRange = [date, `${dateTime.getFullYear()}-${Utils.pad(dateTime.getMonth() + 1, 2)}-${Utils.pad(dateTime.getDate(), 2)}`];
    this.filters.saleDate.value = dateRange;
  }

  createSingleFilterQuery(filter: Filter, filterName: string): string {
    let query: string = this.createCustomQuery(filter, filterName);
    if(query !== '') return query;
    let queryValue = filter.getQueryValue(filter.value);
    if(queryValue === '') return '';
    switch(filter.type) {
      case('dateRange'):
        if(filter.value[0] === null || filter.value[1] === null) break;
        queryValue = filter.getQueryValue(filter.value,0);
        query += `&filters[${filterName}][0][value]=${queryValue}`;
        query += `&filters[${filterName}][0][operator]=gte`;
        queryValue = filter.getQueryValue(filter.value,1);
        query += `&filters[${filterName}][1][value]=${queryValue}`;
        query += `&filters[${filterName}][1][operator]=lte`;
        break;
      case('range'):
        if(filter.value.from === null && filter.value.to === null) break;
          queryValue = filter.getQueryValue(filter.value,0);
          if(queryValue !== null) {
            query += `&filters[${filterName}][0][value]=${queryValue}`;
            query += `&filters[${filterName}][0][operator]=gte`;
          }
          queryValue = filter.getQueryValue(filter.value,1);
        if(queryValue !== null) {
          query += `&filters[${filterName}][1][value]=${queryValue}`;
          query += `&filters[${filterName}][1][operator]=lte`;
        }
        break;
      case('string'): {
        query += `&filters[${filterName}][0][value]=${queryValue}`;
        query += `&filters[${filterName}][0][operator]=${filter.operator}`;
        break;
      }
    }
    return query;
  }

  applyFilters(): void {
    if(this.isLoading) return;
    this.$emit('filtersChange', this.getFilterQuery());
  }

  clearAll() {
    for(let key in this.filters) {
      if(Array.isArray(this.filters[key].value)) {
        this.filters[key].value = [];
      } else if(this.filters[key].value?.to) {
        this.filters[key].value.to = '';
        this.filters[key].value.from = '';
      } else {
        this.filters[key].value = '';
      }
    }
    this.selectedTags = [];
    this.selectedIndustries = [];
    this.applyFilters();
  }

  @Watch('filters.saleDate.value') onSaleDateChange() {
    if(this.filters.saleDate.value && this.filters.saleDate.value[0] && this.filters.saleDate.value[1]) {
      const date = this.filters.saleDate.value[0];
      let dateTime = new Date(date);

      const firstDay = new Date(dateTime.getFullYear(), dateTime.getMonth(), 1);
      const lastDay = new Date(dateTime.getFullYear(), dateTime.getMonth() + 1, 0);
      const formattedFirstDay = `${firstDay.getFullYear()}-${Utils.pad(firstDay.getMonth() + 1, 2)}-${Utils.pad(firstDay.getDate(), 2)}`;
      const formattedLastDay = `${lastDay.getFullYear()}-${Utils.pad(lastDay.getMonth() + 1, 2)}-${Utils.pad(lastDay.getDate(), 2)}`;

      if(formattedFirstDay == this.filters.saleDate.value[0] && formattedLastDay == this.filters.saleDate.value[1]) {
        const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
        const month = monthNames[dateTime.getMonth()];
        this.accountingPeriod = `${month}, ${dateTime.getFullYear()}`;
      } else {
        this.accountingPeriod = '';
      }

    } else {
      this.accountingPeriod = '';
    }
  }
}
