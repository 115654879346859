var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "marketplace-filters filters-container filters table"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-3"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'TITLE'
    },
    model: {
      value: _vm.filters.title.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.title, "value", $$v);
      },
      expression: "filters.title.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'AUTHOR'
    },
    model: {
      value: _vm.filters.author.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.author, "value", $$v);
      },
      expression: "filters.author.value"
    }
  })], 1), _c('div', {
    staticClass: "col-3"
  }, [_c('sygni-date-picker', {
    staticClass: "border-collapse",
    attrs: {
      "label": 'PUBLICATION DATE',
      "range": true
    },
    model: {
      value: _vm.filters.publishedAt.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.publishedAt, "value", $$v);
      },
      expression: "filters.publishedAt.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'LANGUAGE'
    },
    model: {
      value: _vm.filters.language.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.language, "value", $$v);
      },
      expression: "filters.language.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }), _c('div', {
    staticClass: "col-5"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.dictionaries.focusArea,
      "label": "FOCUS AREA"
    },
    model: {
      value: _vm.filters.tags.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.tags, "value", $$v);
      },
      expression: "filters.tags.value"
    }
  })], 1), _c('div', {
    staticClass: "col-5"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.dictionaries.industry,
      "label": "INDUSTRIES"
    },
    model: {
      value: _vm.filters.industry.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.industry, "value", $$v);
      },
      expression: "filters.industry.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('div', {
    staticClass: "filters__actions"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled gn-secondary",
    attrs: {
      "loading": _vm.isLoading,
      "selectable": false
    },
    on: {
      "click": function click($event) {
        return _vm.applyFilters();
      }
    }
  }, [_vm._v(" Show results (" + _vm._s(_vm.itemsCount) + ")")]), _c('div', {
    staticClass: "clear-all-button",
    on: {
      "click": _vm.clearAll
    }
  }, [_vm._v("Clear all")])], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }